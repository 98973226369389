<template>
    <div class="home">
        <v-card>
            <v-container fluid>
                <v-row>
                    <v-col cols="12" style="padding: 0px 12px">
                        <v-tabs v-model="selectedModule">
                            <v-tab v-for="tabName in tabNames" :key="tabName">{{
                                tabName
                            }}</v-tab>
                        </v-tabs>
                    </v-col>
                </v-row>

                <OrderSearch
                    v-if="selectedModule == checkVisibility('Raw History')"
                />
                <TransactionSearch
                    v-if="selectedModule == checkVisibility('Transaction')"
                />
                <!-- <ProfitLossSearch v-if="selectedModule == checkVisibility('Profit & Loss')" />
                <VolumeSearch v-if="selectedModule == checkVisibility('Volume')" />
                <TickSpreadSearch v-if="selectedModule == checkVisibility('Tick Spread Daily')" /> -->
                <DownloadStatus
                    v-if="
                        selectedModule ==
                        checkVisibility('Download Request Status')
                    "
                />
            </v-container>
        </v-card>
    </div>
</template>
<script>
import VolumeSearch from "./component/ComponentVolumeSearch";
import OrderSearch from "./component/ComponentOrderSearch";
import TransactionSearch from "./component/ComponentTransactionSearch";
import ProfitLossSearch from "./component/ComponentProfitLossSearch";
import TickSpreadSearch from "./component/ComponentTickSpread";
import DownloadStatus from "./component/ComponentDownloadStatus";
import { mapState, mapActions } from "vuex";
import { permission } from "@components/mixins/permission";
import dayjs from "dayjs";
import introJs from "intro.js";
import { TutorialConstant } from "@assets/tutorialConstant";
import { putAuthUser } from "@services/account/auth";
import Breadcrumbs from "@components/Breadcrumbs";
dayjs.locale("en-au");

export default {
    components: {
        Breadcrumbs,
        OrderSearch,
        TransactionSearch,
        ProfitLossSearch,
        TickSpreadSearch,
        VolumeSearch,
        DownloadStatus,
    },
    mixins: [permission],
    data() {
        return {
            tutorialConstant: TutorialConstant,
            selectedModule: "",
            showDownload: false,
            tabNames: [
                "Raw History",
                "Transaction",
                // "Profit & Loss",
                // "Volume",
                // "Tick Spread Daily",
            ],
        };
    },
    computed: {
        ...mapState("RawHistory", ["searchTutorial"]),
    },
    watch: {
        searchTutorial(nv) {
            if (!!nv) {
                this.getTutorial()
                    .oncomplete(() => this.updateSearchTutorialAction(false))
                    .onexit(() => this.updateSearchTutorialAction(false))
                    .start();
            }
        },
    },
    methods: {
        ...mapActions("RawHistory", ["updateSearchTutorialAction"]),
        getTutorial() {
            if (
                this.selectedModule == this.checkVisibility("Raw History") ||
                this.selectedModule == this.checkVisibility("Transaction")
            ) {
                return introJs().setOptions({
                    steps: [
                        {
                            element: document.getElementById("searchFilter"),
                            title: this.tutorialConstant["searchPageIntro"][
                                "searchFilter"
                            ]["title"],
                            intro: this.tutorialConstant["searchPageIntro"][
                                "searchFilter"
                            ]["intro"],
                        },
                        {
                            element:
                                document.getElementById("moreSearchFilter"),
                            title: this.tutorialConstant["searchPageIntro"][
                                "moreSearchFilter"
                            ]["title"],
                            intro: this.tutorialConstant["searchPageIntro"][
                                "moreSearchFilter"
                            ]["intro"],
                        },
                        {
                            element: document.getElementById("searchButton"),
                            title: this.tutorialConstant["searchPageIntro"][
                                "searchButton"
                            ]["title"],
                            intro: this.tutorialConstant["searchPageIntro"][
                                "searchButton"
                            ]["intro"],
                        },
                        {
                            element: document.getElementById("searchDownload"),
                            title: this.tutorialConstant["searchPageIntro"][
                                "searchDownload"
                            ]["title"],
                            intro: this.tutorialConstant["searchPageIntro"][
                                "searchDownload"
                            ]["intro"],
                        },
                        {
                            element: document.getElementById("searchTable"),
                            title: this.tutorialConstant["searchPageIntro"][
                                "searchTable"
                            ]["title"],
                            intro: this.tutorialConstant["searchPageIntro"][
                                "searchTable"
                            ]["intro"],
                        },
                    ],
                });
            }
            if (
                this.selectedModule ==
                this.checkVisibility("Download Request Status")
            ) {
                return introJs().setOptions({
                    steps: [
                        {
                            element: document.getElementById(
                                "searchDownloadTable"
                            ),
                            title: this.tutorialConstant["searchPageIntro"][
                                "searchDownloadTable"
                            ]["title"],
                            intro: this.tutorialConstant["searchPageIntro"][
                                "searchDownloadTable"
                            ]["intro"],
                        },
                        {
                            element: document.getElementById(
                                "searchDownloadRefresh"
                            ),
                            title: this.tutorialConstant["searchPageIntro"][
                                "searchDownloadRefresh"
                            ]["title"],
                            intro: this.tutorialConstant["searchPageIntro"][
                                "searchDownloadRefresh"
                            ]["intro"],
                        },
                    ],
                });
            }
        },
        changeView(key) {
            this.selectedModule = key;
        },
        checkVisibility(key) {
            return this.tabNames.indexOf(key);
        },
    },
    mounted() {
        this.showDownload =
            JSON.parse(
                localStorage.getItem("permission")
            )?.frontPermission?.search?.child?.includes(
                "allowDownloadRequest"
            ) || false;
        if (!!this.showDownload) {
            this.tabNames.push("Download Request Status");
            if (
                !!this.$route.query?.downloadTab &&
                this.$route.query?.downloadTab == "true"
            ) {
                this.changeView(
                    this.checkVisibility("Download Request Status")
                );
            }
        }
        setTimeout(() => {
            if (
                !localStorage
                    .getItem("tutorial_presented")
                    .includes("search_page")
            ) {
                this.getTutorial()
                    .oncomplete(function () {
                        const params = { pages: "" };
                        if (!localStorage.getItem("tutorial_presented")) {
                            params.pages = "search_page";
                        } else {
                            params.pages =
                                localStorage.getItem("tutorial_presented") +
                                ",search_page";
                        }
                        putAuthUser(params).then((res) => {
                            if (res.status === 200) {
                                localStorage.setItem(
                                    "tutorial_presented",
                                    params.pages
                                );
                            }
                        });
                    })
                    .onexit(function () {
                        const params = { pages: "" };
                        if (!localStorage.getItem("tutorial_presented")) {
                            params.pages = "search_page";
                        } else {
                            params.pages =
                                localStorage.getItem("tutorial_presented") +
                                ",search_page";
                        }
                        putAuthUser(params).then((res) => {
                            if (res.status === 200) {
                                localStorage.setItem(
                                    "tutorial_presented",
                                    params.pages
                                );
                            }
                        });
                    })
                    .start();
            }
        }, 500);
    },
};
</script>
