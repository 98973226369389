<template>
    <div>
        <v-row>
            <v-col cols="12">
                <v-card outlined>
                    <v-card-title
                        style="border-bottom: 1px solid #404040"
                        class="pt-2 pb-2"
                    >
                        <div
                            class="text-left"
                            style="
                                margin-left: 0;
                                width: 50%;
                                font-size: 16px;
                                font-weight: normal;
                            "
                        >
                            SEARCH FILTER
                        </div>
                    </v-card-title>

                    <v-row class="pl-4 pt-4" id="searchFilter">
                        <v-col
                            lg="12"
                            md="12"
                            xs="12"
                            cols="12"
                            style="padding-top: 0"
                        >
                            <v-alert
                                outlined
                                type="info"
                                border="left"
                                dense
                                style="margin-right: 15px; margin-bottom: 0"
                                dismissible
                            >
                                Please be patient, searches may take a few
                                moments due to the size of transaction.
                                <br />Click "REQUEST CSV" to generate a
                                downloadable csv file. Notice this is a backend
                                task due to possible large datasets, so once the
                                request has been sent, please wait for
                                confirmation email telling you can download the
                                file. Also you can check the status under
                                "DOWNLOAD REQUEST STATUS" tab.
                            </v-alert>
                        </v-col>
                        <v-col
                            cols="12"
                            sm="12"
                            md="4"
                            lg="4"
                            xl="4"
                            class="pb-0"
                        >
                            <v-autocomplete
                                label="Filter Server"
                                hide-details
                                v-model="selectedServer"
                                :items="sortedServerData"
                                item-text="server_name"
                                item-value="id"
                                auto-select-first
                                multiple
                                chips
                                clearable
                                deletable-chips
                                small-chips
                            >
                                <template v-slot:selection="{ item, index }">
                                    <v-chip
                                        small
                                        v-if="index < 3"
                                        close
                                        @click:close="
                                            () => removeServer(item.id)
                                        "
                                    >
                                        <span>{{ item.server_name }}</span>
                                    </v-chip>
                                    <span
                                        v-if="index === 3"
                                        class="grey--text text-caption"
                                    >
                                        (+{{ selectedServer.length - 3 }}
                                        others)
                                    </span>
                                </template>
                            </v-autocomplete>
                        </v-col>
                        <v-col
                            cols="12"
                            sm="12"
                            md="4"
                            lg="2"
                            xl="2"
                            class="pb-0"
                        >
                            <v-datetime-picker
                                hide-details
                                label="Start Date"
                                v-model="selectedStartDate"
                            ></v-datetime-picker>
                        </v-col>
                        <v-col
                            cols="12"
                            sm="12"
                            md="4"
                            lg="2"
                            xl="2"
                            class="pb-0"
                        >
                            <v-datetime-picker
                                hide-details
                                label="End Date"
                                v-model="selectedEndDate"
                            ></v-datetime-picker>
                        </v-col>
                        <v-col
                            cols="12"
                            sm="12"
                            md="4"
                            lg="2"
                            xl="2"
                            class="pb-0"
                        >
                            <div class="text-left" style="margin-left: 20px">
                                <div>
                                    <v-btn
                                        text
                                        icon
                                        color="blue lighten-2"
                                        @click="showMore()"
                                        id="moreSearchFilter"
                                        v-if="!showMoreStatus"
                                    >
                                        More
                                        <v-icon large>
                                            mdi-chevron-down
                                        </v-icon>
                                    </v-btn>
                                    <v-btn
                                        text
                                        icon
                                        color="blue lighten-2"
                                        @click="showMore()"
                                        v-if="showMoreStatus"
                                    >
                                        Fewer
                                        <v-icon large> mdi-chevron-up </v-icon>
                                    </v-btn>
                                </div>
                            </div>
                        </v-col>
                    </v-row>
                    <v-row class="pl-4" v-if="showMoreStatus">
                        <v-col
                            cols="12"
                            sm="12"
                            md="4"
                            lg="2"
                            xl="2"
                            class="pb-0"
                        >
                            <v-autocomplete
                                label="Order By"
                                hide-details
                                v-model="selectedOrderBy"
                                :items="filterOrderBy"
                                auto-select-first
                                chips
                                clearable
                                deletable-chips
                                small-chips
                            ></v-autocomplete>
                        </v-col>
                        <v-col cols="2" class="pb-0">
                            <v-switch
                                style="width: 100%"
                                v-model="isAsc"
                                class="mt-2"
                                :label="`Is Ascending Order?`"
                                hide-details
                            ></v-switch>
                        </v-col>
                        <v-col cols="12" class="pb-0">
                            <v-text-field
                                label="Filter IB (Comma to Separate Multiple IB ID)"
                                v-if="showIBFields"
                                v-model="selectedPartnerID"
                                hide-details
                            ></v-text-field
                        ></v-col>
                        <v-col cols="12" class="pb-0">
                            <v-text-field
                                label="Filter Back Office (Comma to Separate Multiple Back Office ID)"
                                v-if="showIBFields"
                                v-model="selectedBackOfficeID"
                                hide-details
                            ></v-text-field
                        ></v-col>
                        <v-col cols="12" class="pb-0">
                            <v-text-field
                                label="Filter Customer ID (Comma to Separate Multiple Customer ID)"
                                v-if="showIBFields"
                                v-model="selectedCustomerID"
                                hide-details
                            ></v-text-field
                        ></v-col>
                        <v-col cols="12" class="pb-0">
                            <v-text-field
                                label="Filter Login (Comma to Separate Multi Login, Eg: 100000000,200000000,30000000)"
                                v-model="selectedLogin"
                                hide-details="auto"
                            ></v-text-field>
                        </v-col>
                    </v-row>
                    <v-row class="pa-4 pt-0">
                        <v-col cols="4">
                            <v-btn
                                small
                                v-on:click="fetchTransactionRecord()"
                                :loading="btnLoading"
                                id="searchButton"
                                color="primary"
                                >SEARCH</v-btn
                            >
                            <v-btn
                                small
                                class="ml-2"
                                v-show="showDownload"
                                v-on:click="requestCSV()"
                                :loading="btnLoading"
                                color="orange"
                                id="searchDownload"
                                >Request CSV</v-btn
                            >
                        </v-col>
                    </v-row>
                </v-card>
            </v-col>
        </v-row>

        <v-row>
            <v-col cols="12">
                <v-card outlined>
                    <v-card-title
                        style="border-bottom: 1px solid #404040"
                        class="pt-2 pb-2"
                    >
                        <div
                            class="text-left"
                            style="
                                margin-left: 0;
                                width: 100%;
                                font-size: 16px;
                                font-weight: normal;
                            "
                        >
                            RESULT
                        </div>
                    </v-card-title>

                    <v-row>
                        <v-col cols="12">
                            <v-card flat>
                                <v-data-table
                                    dense
                                    id="searchTable"
                                    :headers="computedHeaders"
                                    :loading="loading"
                                    :items="tradesList"
                                    class="elevation-1"
                                    :items-per-page.sync="offset"
                                    :header-props="{ sortIcon: null }"
                                    :page.sync="currentPage"
                                    :server-items-length="dataAmount"
                                    :footer-props="{
                                        pagination: {
                                            page: currentPage,
                                            itemsPerPage: offset,
                                            pageStart:
                                                (currentPage - 1) * offset,
                                            pageStop: currentPage * offset,
                                            pageCount: Math.ceil(
                                                dataAmount / offset
                                            ),
                                            itemsLength: dataAmount,
                                        },
                                        'items-per-page-options': [
                                            30, 50, 100, 300, 500, 1000,
                                        ],
                                        'show-current-page': true,
                                    }"
                                >
                                    <template v-slot:item.partner_id="{ item }">
                                        {{
                                            !!item.partner_id
                                                ? item.partner_id
                                                : "N/A"
                                        }}
                                    </template>
                                    <template
                                        v-slot:item.back_office_id="{ item }"
                                    >
                                        {{
                                            !!item.back_office_id
                                                ? item.back_office_id
                                                : "N/A"
                                        }}
                                    </template>
                                    <template v-slot:item.account_id="{ item }">
                                        {{
                                            !!item.account_id
                                                ? item.account_id
                                                : "N/A"
                                        }}
                                    </template>
                                </v-data-table>
                            </v-card>
                        </v-col>
                    </v-row>
                </v-card>
            </v-col>
        </v-row>
    </div>
</template>
<script>
import { mapState, mapActions } from "vuex";
import { snackbar } from "@components/mixins/snackbar";
import { permission } from "@components/mixins/permission";
import { helper } from "@components/mixins/helper";

export default {
    mixins: [snackbar, permission, helper],
    data() {
        return {
            showMoreStatus: false,
            selectedServer: [],
            selectedLogin: "",
            selectedStartDate: null,
            selectedEndDate: null,
            selectedOrderBy: "login",
            isAsc: false,
            selectedPartnerID: "",
            selectedBackOfficeID: "",
            selectedCustomerID: "",
            showIBFields: false,

            offset: 30,
            currentPage: 1,
            timeout: null,
            showDownload: false,
        };
    },
    computed: {
        ...mapState("Transaction", [
            "csvHeader",
            "filterOrderBy",
            "headers",
            "filterShowResult",
            "loading",
            "btnLoading",
            "tradesList",
            "dataAmount",
        ]),
        ...mapState("SettingsServer", ["serverData"]),
        sortedServerData() {
            return this.serverData.sort((a, b) => {
                return a.server_name.localeCompare(b.server_name);
            });
        },
        computedHeaders() {
            if (this.showIBFields) {
                return [
                    {
                        text: "IB",
                        value: "partner_id",
                        align: "left",
                        width: 80,
                    },
                    {
                        text: "Back Office",
                        value: "back_office_id",
                        align: "left",
                        width: 100,
                    },
                    {
                        text: "Customer",
                        value: "account_id",
                        align: "left",
                        width: 100,
                    },
                    ...this.headers,
                ];
            } else {
                return this.headers;
            }
        },
    },
    watch: {
        sortedServerData(nv) {
            if (nv.length !== 0) this.selectedServer = [nv[0].id];
        },
        offset() {
            this.fetchTransactionRecord();
        },
        currentPage() {
            this.fetchTransactionRecord();
        },
    },
    methods: {
        ...mapActions("Transaction", [
            "getTransactionAction",
            "requestTransactionAction",
        ]),
        removeServer(server) {
            this.selectedServer = this.selectedServer.filter(
                (item) => item != server
            );
        },
        /**
         * 获取转账记录
         * @return {[type]} [description]
         */
        fetchTransactionRecord() {
            this.timeout && clearTimeout(this.timeout);
            this.timeout = setTimeout(() => {
                const self = this;

                if (!(self.selectedStartDate && self.selectedEndDate)) {
                    self.snackBarDanger(
                        "Please select start and end time to complete the search"
                    );
                    return;
                }

                const trimmedLogins = self.selectedLogin.replaceAll(" ", "");
                const trimmedPartnerID = self.selectedPartnerID.replaceAll(
                    " ",
                    ""
                );
                const trimmedBackOfficeID =
                    self.selectedBackOfficeID.replaceAll(" ", "");
                const trimmedCustomerID = self.selectedCustomerID.replaceAll(
                    " ",
                    ""
                );

                const loginRulePattern = /^[0-9,]+$/;
                const passLoginsRuleTest = loginRulePattern.test(trimmedLogins);

                if (!passLoginsRuleTest && trimmedLogins !== "") {
                    this.snackBarDanger(
                        "Filter Login exist disallowed characters, please use only numbers for logins"
                    );
                    return;
                }

                const params = {
                    Selected_Login: trimmedLogins,
                    Selected_Server: this.selectedServer,
                    Order_By: this.selectedOrderBy,
                    Start_Time: this.formatDateTime(this.selectedStartDate),
                    End_Time: this.formatDateTime(this.selectedEndDate),
                    Is_Asc: this.isAsc,
                    offset: this.offset,
                    page: this.currentPage,
                    downloadRequest: false,
                };
                if (this.showIBFields) {
                    params["selected_partner"] = trimmedPartnerID;
                    params["selected_back_office"] = trimmedBackOfficeID;
                    params["selected_customer"] = trimmedCustomerID;
                }
                self.getTransactionAction(params);
            }, 100);
        },
        requestCSV() {
            if (!(this.selectedStartDate && this.selectedEndDate)) {
                this.snackBarDanger(
                    "Please select start and end time to complete the search"
                );
                return;
            }

            const trimmedLogins = this.selectedLogin.replaceAll(" ", "");
            const trimmedPartnerID = this.selectedPartnerID.replaceAll(" ", "");
            const trimmedBackOfficeID = this.selectedBackOfficeID.replaceAll(
                " ",
                ""
            );
            const trimmedCustomerID = this.selectedCustomerID.replaceAll(
                " ",
                ""
            );

            const loginRulePattern = /^[0-9,]+$/;
            const passLoginsRuleTest = loginRulePattern.test(trimmedLogins);

            if (!passLoginsRuleTest && trimmedLogins !== "") {
                this.snackBarDanger(
                    "Filter Login exist disallowed characters, please use only numbers for logins"
                );
                return;
            }

            const params = {
                Selected_Login: trimmedLogins,
                Selected_Server: this.selectedServer,
                Order_By: this.selectedOrderBy,
                Start_Time: this.formatDateTime(this.selectedStartDate),
                End_Time: this.formatDateTime(this.selectedEndDate),
                Is_Asc: this.isAsc,
                offset: this.offset,
                page: this.currentPage,
                downloadRequest: true,
            };
            if (this.showIBFields) {
                params["selected_partner"] = trimmedPartnerID;
                params["selected_back_office"] = trimmedBackOfficeID;
                params["selected_customer"] = trimmedCustomerID;
            }
            this.requestTransactionAction(params);
        },
        setFocus(item) {
            setTimeout(() => {
                this.$refs[`${item}`].focus();
            }, 200);
        },
        showMore() {
            this.showMoreStatus = !this.showMoreStatus;
        },
    },
    mounted() {
        let now = new Date();
        this.selectedEndDate = new Date(
            now.getFullYear(),
            now.getMonth(),
            now.getDate()
        );
        const days = 60;
        const dateOffset = 24 * 60 * 60 * 1000 * days; // 60 days
        const startDate = new Date();
        startDate.setTime(startDate.getTime() - dateOffset);
        this.selectedStartDate = new Date(
            startDate.getFullYear(),
            startDate.getMonth(),
            startDate.getDate()
        );

        if (this.serverData.length !== 0) {
            this.selectedServer = [this.sortedServerData[0].id];
        }
    },
    created() {
        this.showDownload =
            JSON.parse(
                localStorage.getItem("permission")
            )?.frontPermission?.search?.child?.includes(
                "allowDownloadRequest"
            ) || false;
        this.showIBFields =
            JSON.parse(
                localStorage.getItem("permission")
            )?.frontPermission?.search?.child?.includes("ibRelatedFields") ||
            false;
    },
};
</script>
<style>
.v-text-field__details {
    display: none;
}
</style>
