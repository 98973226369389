<template>
    <div>
        <v-row>
            <v-col cols="12">
                <v-card outlined>
                    <v-card-title
                        style="border-bottom: 1px solid #404040"
                        class="pt-2 pb-2"
                    >
                        <div
                            class="text-left"
                            style="
                                margin-left: 0;
                                width: 50%;
                                font-size: 16px;
                                font-weight: normal;
                            "
                        >
                            SEARCH FILTER
                        </div>
                    </v-card-title>

                    <v-row class="pl-4 pt-4" id="searchFilter">
                        <v-col
                            lg="12"
                            md="12"
                            xs="12"
                            cols="12"
                            style="padding-top: 0"
                        >
                            <v-alert
                                outlined
                                type="info"
                                border="left"
                                dense
                                style="margin-right: 15px; margin-bottom: 0"
                                dismissible
                            >
                                Please be patient, searches may take a few
                                moments due to the size of trades history.
                                <br />Click "REQUEST CSV" to generate a
                                downloadable csv file. Notice this is a backend
                                task due to possible large datasets, so once the
                                request has been sent, please wait for
                                confirmation email telling you can download the
                                file. Also you can check the status under
                                "DOWNLOAD REQUEST STATUS" tab.
                            </v-alert>
                        </v-col>

                        <v-col
                            cols="12"
                            sm="12"
                            md="4"
                            lg="4"
                            xl="4"
                            class="pb-0"
                        >
                            <v-autocomplete
                                label="Filter Server"
                                v-model="selectedServer"
                                :items="sortedServerData"
                                item-text="server_name"
                                item-value="id"
                                auto-select-first
                                chips
                                clearable
                                multiple
                                small-chips
                                hide-details
                            >
                                <template v-slot:selection="{ item, index }">
                                    <v-chip
                                        small
                                        v-if="index < 3"
                                        close
                                        @click:close="
                                            () => removeServer(item.id)
                                        "
                                    >
                                        <span>{{ item.server_name }}</span>
                                    </v-chip>
                                    <span
                                        v-if="index === 3"
                                        class="grey--text text-caption"
                                    >
                                        (+{{ selectedServer.length - 3 }}
                                        others)
                                    </span>
                                </template>
                            </v-autocomplete>
                        </v-col>
                        <v-col
                            cols="12"
                            sm="12"
                            md="4"
                            lg="2"
                            xl="2"
                            class="pb-0"
                        >
                            <v-autocomplete
                                v-model="selectedCmd"
                                :items="filterCmdLists"
                                item-text="name"
                                item-value="cmd"
                                label="Filter Order Type"
                                auto-select-first
                                chips
                                clearable
                                deletable-chips
                                multiple
                                small-chips
                                hide-details
                            >
                            </v-autocomplete>
                        </v-col>
                        <v-col
                            cols="12"
                            sm="12"
                            md="4"
                            lg="2"
                            xl="2"
                            class="pb-0"
                        >
                            <div class="text-left" style="margin-left: 20px">
                                <div>
                                    <v-btn
                                        text
                                        icon
                                        color="blue lighten-2"
                                        @click="showMore()"
                                        v-if="!showMoreStatus"
                                        id="moreSearchFilter"
                                    >
                                        More<v-icon large
                                            >mdi-chevron-down</v-icon
                                        >
                                    </v-btn>
                                    <v-btn
                                        text
                                        icon
                                        color="blue lighten-2"
                                        @click="showMore()"
                                        v-if="showMoreStatus"
                                    >
                                        Fewer<v-icon large
                                            >mdi-chevron-up</v-icon
                                        >
                                    </v-btn>
                                </div>
                            </div>
                        </v-col>
                    </v-row>
                    <v-row class="pl-4" v-if="showMoreStatus">
                        <v-col
                            cols="12"
                            sm="12"
                            md="4"
                            lg="2"
                            xl="2"
                            class="pb-0"
                        >
                            <v-datetime-picker
                                label="Filter Open Time (Start)"
                                v-model="selectedOpenTimeStart"
                                hide-details
                            ></v-datetime-picker>
                        </v-col>
                        <v-col
                            cols="12"
                            sm="12"
                            md="4"
                            lg="2"
                            xl="2"
                            class="pb-0"
                        >
                            <v-datetime-picker
                                label="Filter Open Time (End)"
                                v-model="selectedOpenTimeEnd"
                                hide-details
                            ></v-datetime-picker>
                        </v-col>
                        <v-col
                            cols="12"
                            sm="12"
                            md="4"
                            lg="2"
                            xl="2"
                            class="pb-0"
                        >
                            <v-datetime-picker
                                label="Filter Close Time (Start)"
                                v-model="selectedCloseTimeStart"
                                hide-details
                            >
                            </v-datetime-picker>
                        </v-col>
                        <v-col
                            cols="12"
                            sm="12"
                            md="4"
                            lg="2"
                            xl="2"
                            class="pb-0"
                        >
                            <v-datetime-picker
                                label="Filter Close Time (End)"
                                v-model="selectedCloseTimeEnd"
                                hide-details
                            ></v-datetime-picker>
                        </v-col>
                    </v-row>

                    <v-row class="pl-4" v-if="showMoreStatus">
                        <v-col
                            cols="12"
                            sm="12"
                            md="4"
                            lg="2"
                            xl="2"
                            class="pb-0"
                        >
                            <v-text-field
                                label="Enter Symbol to Search"
                                v-model="selectedSymbol"
                                hide-details
                            >
                            </v-text-field>
                        </v-col>
                        <v-col
                            cols="12"
                            sm="12"
                            md="4"
                            lg="2"
                            xl="2"
                            class="pb-0"
                        >
                            <v-autocomplete
                                label="Order By"
                                v-model="selectedOrderBy"
                                :items="filterOrderBy"
                                auto-select-first
                                hide-details
                                chips
                                clearable
                                deletable-chips
                                small-chips
                            ></v-autocomplete>
                        </v-col>
                        <v-col cols="2" class="pb-0">
                            <v-switch
                                style="width: 100%"
                                v-model="isAsc"
                                hide-details
                                class="mt-2"
                                :label="`Is Ascending Order?`"
                            ></v-switch>
                        </v-col>
                        <v-col cols="12" class="pb-0">
                            <v-text-field
                                label="Filter IB (Comma to Separate Multiple IB ID)"
                                v-if="showIBFields"
                                v-model="selectedPartnerID"
                                hide-details
                            ></v-text-field
                        ></v-col>
                        <v-col cols="12" class="pb-0">
                            <v-text-field
                                label="Filter Back Office (Comma to Separate Multiple Back Office ID)"
                                v-if="showIBFields"
                                v-model="selectedBackOfficeID"
                                hide-details
                            ></v-text-field
                        ></v-col>
                        <v-col cols="12" class="pb-0">
                            <v-text-field
                                label="Filter Customer ID (Comma to Separate Multiple Customer ID)"
                                v-if="showIBFields"
                                v-model="selectedCustomerID"
                                hide-details
                            ></v-text-field
                        ></v-col>
                        <v-col cols="12" class="pb-0">
                            <v-text-field
                                label="Filter Login (Comma to Separate Multiple Login, Eg: 100000000,200000000,30000000)"
                                v-model="selectedLogin"
                                hide-details
                            ></v-text-field
                        ></v-col>
                    </v-row>

                    <v-row class="pa-4 pt-0">
                        <v-col lg="12" md="12" xs="12" cols="12">
                            <v-btn
                                small
                                v-on:click="fetchTradeRecord()"
                                :loading="btnLoading"
                                color="primary"
                                id="searchButton"
                                >SEARCH</v-btn
                            >
                            <v-btn
                                small
                                class="ml-2"
                                v-show="showDownload"
                                v-on:click="requestCSV()"
                                :loading="btnLoading"
                                color="orange"
                                id="searchDownload"
                                >Request CSV</v-btn
                            >
                        </v-col>
                    </v-row>
                </v-card>
            </v-col>
        </v-row>

        <v-row>
            <v-col cols="12">
                <v-card outlined>
                    <v-card-title
                        style="border-bottom: 1px solid #404040"
                        class="pt-2 pb-2"
                    >
                        <div
                            class="text-left"
                            style="
                                margin-left: 0;
                                width: 100%;
                                font-size: 16px;
                                font-weight: normal;
                            "
                        >
                            RESULT
                        </div>
                    </v-card-title>

                    <v-data-table
                        id="searchTable"
                        dense
                        :search="search"
                        :headers="computedHeaders"
                        :loading="loading"
                        :items="tradesList"
                        class="elevation-1"
                        :items-per-page.sync="offset"
                        :header-props="{ sortIcon: null }"
                        :page.sync="currentPage"
                        :server-items-length="dataAmount"
                        :footer-props="{
                            pagination: {
                                page: currentPage,
                                itemsPerPage: offset,
                                pageStart: (currentPage - 1) * offset,
                                pageStop: currentPage * offset,
                                pageCount: Math.ceil(dataAmount / offset),
                                itemsLength: dataAmount,
                            },
                            'items-per-page-options': [
                                30, 50, 100, 300, 500, 1000,
                            ],
                            'show-current-page': true,
                        }"
                    >
                        <template v-slot:item.partner_id="{ item }">
                            {{ !!item.partner_id ? item.partner_id : "N/A" }}
                        </template>
                        <template v-slot:item.back_office_id="{ item }">
                            {{
                                !!item.back_office_id
                                    ? item.back_office_id
                                    : "N/A"
                            }}
                        </template>
                        <template v-slot:item.account_id="{ item }">
                            {{ !!item.account_id ? item.account_id : "N/A" }}
                        </template>
                        <template v-slot:item.profit="{ item }">
                            {{ numberWithCommas(item.profit.toFixed(2)) }}
                        </template>
                        <template v-slot:item.comment="{ item }">
                            <div v-if="item.comment.length <= 9">
                                {{ item.comment }}
                            </div>
                            <v-tooltip top v-else>
                                <template v-slot:activator="{ on, attrs }">
                                    <div v-bind="attrs" v-on="on">
                                        {{ item.comment.slice(0, 9)
                                        }}<v-icon small
                                            >mdi-dots-horizontal</v-icon
                                        >
                                    </div>
                                </template>
                                <span>{{ item.comment }}</span>
                            </v-tooltip>
                        </template>
                    </v-data-table>
                </v-card>
            </v-col>
        </v-row>
    </div>
</template>
<script>
import { snackbar } from "@components/mixins/snackbar";
import { permission } from "@components/mixins/permission";
import { helper } from "@components/mixins/helper";
import { mapState, mapActions } from "vuex";

export default {
    mixins: [permission, snackbar, helper],
    data() {
        return {
            selectedServer: [],
            selectedCmd: [0, 1],
            selectedSymbol: "",
            selectedOpenTimeStart: null,
            selectedOpenTimeEnd: null,
            selectedCloseTimeStart: null,
            selectedCloseTimeEnd: null,
            selectedOrderBy: "open_time",
            isAsc: false,
            selectedLogin: "",
            selectedPartnerID: "",
            selectedBackOfficeID: "",
            selectedCustomerID: "",
            showMoreStatus: false,
            showIBFields: false,

            search: "",
            offset: 30,
            currentPage: 1,
            timeout: null,
            showDownload: false,
        };
    },
    computed: {
        ...mapState("RawHistory", [
            "filterOrderBy",
            "filterCmdLists",
            "csvHeader",
            "headers",
            "loading",
            "btnLoading",
            "tradesList",
            "openTimeMin",
            "openTimeMax",
            "openTimeRange",
            "openTimePeriod",
            "closeTimeMin",
            "closeTimeMax",
            "closeTimeRange",
            "closeTimePeriod",
            "dataAmount",
        ]),
        ...mapState("Home", ["symbolDP"]),
        ...mapState("SettingsServer", ["serverData"]),
        sortedServerData() {
            return this.serverData.sort((a, b) => {
                return a.server_name.localeCompare(b.server_name);
            });
        },
        computedHeaders() {
            if (this.showIBFields) {
                return [
                    {
                        text: "IB",
                        value: "partner_id",
                        align: "left",
                        width: 80,
                    },
                    {
                        text: "Back Office",
                        value: "back_office_id",
                        align: "left",
                        width: 100,
                    },
                    {
                        text: "Customer",
                        value: "account_id",
                        align: "left",
                        width: 100,
                    },
                    ...this.headers,
                ];
            } else {
                return this.headers;
            }
        },
    },
    watch: {
        sortedServerData(nv) {
            if (nv.length !== 0) this.selectedServer = [nv[0].id];
        },
        offset() {
            this.fetchTradeRecord();
        },
        currentPage() {
            this.fetchTradeRecord();
        },
    },
    methods: {
        ...mapActions("RawHistory", [
            "getRawHistoryAction",
            "requestRawHistoryAction",
        ]),
        removeServer(server) {
            this.selectedServer = this.selectedServer.filter(
                (item) => item != server
            );
        },
        showMore() {
            this.showMoreStatus = !this.showMoreStatus;
        },
        fetchTradeRecord() {
            this.timeout && clearTimeout(this.timeout);
            this.timeout = setTimeout(() => {
                const self = this;

                const trimmedLogins = self.selectedLogin.replaceAll(" ", "");
                const trimmedPartnerID = self.selectedPartnerID.replaceAll(
                    " ",
                    ""
                );
                const trimmedBackOfficeID =
                    self.selectedBackOfficeID.replaceAll(" ", "");
                const trimmedCustomerID = self.selectedCustomerID.replaceAll(
                    " ",
                    ""
                );

                const loginRulePattern = /^[0-9,]+$/;
                const passLoginsRuleTest = loginRulePattern.test(trimmedLogins);

                if (!passLoginsRuleTest && trimmedLogins !== "") {
                    this.snackBarDanger(
                        "Filter Login exist disallowed characters, please use only numbers for logins"
                    );
                    return;
                }

                const params = {
                    Selected_Login: trimmedLogins,
                    Selected_Order_Type: this.selectedCmd,
                    Selected_Server: this.selectedServer,
                    Selected_Symbol: this.selectedSymbol,
                    Open_Time_Start: this.formatDateTime(
                        this.selectedOpenTimeStart
                    ),
                    Open_Time_End: this.formatDateTime(
                        this.selectedOpenTimeEnd
                    ),
                    Close_Time_Start: this.formatDateTime(
                        this.selectedCloseTimeStart
                    ),
                    Close_Time_End: this.formatDateTime(
                        this.selectedCloseTimeEnd
                    ),
                    Order_By: this.selectedOrderBy,
                    Is_Asc: this.isAsc,
                    offset: this.offset,
                    page: this.currentPage,
                    downloadRequest: false,
                };
                if (this.showIBFields) {
                    params["selected_partner"] = trimmedPartnerID;
                    params["selected_back_office"] = trimmedBackOfficeID;
                    params["selected_customer"] = trimmedCustomerID;
                }
                self.getRawHistoryAction(params);
            }, 100);
        },
        requestCSV() {
            const trimmedLogins = this.selectedLogin.replaceAll(" ", "");
            const trimmedPartnerID = this.selectedPartnerID.replaceAll(" ", "");
            const trimmedBackOfficeID = this.selectedBackOfficeID.replaceAll(
                " ",
                ""
            );
            const trimmedCustomerID = this.selectedCustomerID.replaceAll(
                " ",
                ""
            );

            const loginRulePattern = /^[0-9,]+$/;
            const passLoginsRuleTest = loginRulePattern.test(trimmedLogins);

            if (!passLoginsRuleTest && trimmedLogins !== "") {
                this.snackBarDanger(
                    "Filter Login exist disallowed characters, please use only numbers for logins"
                );
                return;
            }

            const params = {
                Selected_Login: trimmedLogins,
                Selected_Order_Type: this.selectedCmd,
                Selected_Server: this.selectedServer,
                Selected_Symbol: this.selectedSymbol,
                Open_Time_Start: this.formatDateTime(
                    this.selectedOpenTimeStart
                ),
                Open_Time_End: this.formatDateTime(this.selectedOpenTimeEnd),
                Close_Time_Start: this.formatDateTime(
                    this.selectedCloseTimeStart
                ),
                Close_Time_End: this.formatDateTime(this.selectedCloseTimeEnd),
                Order_By: this.selectedOrderBy,
                Is_Asc: this.isAsc,
                offset: this.offset,
                page: this.currentPage,
                downloadRequest: true,
            };
            if (this.showIBFields) {
                params["selected_partner"] = trimmedPartnerID;
                params["selected_back_office"] = trimmedBackOfficeID;
                params["selected_customer"] = trimmedCustomerID;
            }
            this.requestRawHistoryAction(params);
        },
        setFocus(item) {
            setTimeout(() => {
                this.$refs[`${item}`].focus();
            }, 200);
        },
    },
    mounted() {
        let now = new Date();
        this.selectedCloseTimeEnd = new Date(
            now.getFullYear(),
            now.getMonth(),
            now.getDate()
        );

        const days = 2;
        const dateOffset = 24 * 60 * 60 * 1000 * days;
        const startDate = new Date();
        startDate.setTime(startDate.getTime() - dateOffset);
        this.selectedCloseTimeStart = new Date(
            startDate.getFullYear(),
            startDate.getMonth(),
            startDate.getDate()
        );

        if (this.serverData.length !== 0) {
            this.selectedServer = [this.sortedServerData[0].id];
        }
    },
    created() {
        this.showDownload =
            JSON.parse(
                localStorage.getItem("permission")
            )?.frontPermission?.search?.child?.includes(
                "allowDownloadRequest"
            ) || false;
        this.showIBFields =
            JSON.parse(
                localStorage.getItem("permission")
            )?.frontPermission?.search?.child?.includes("ibRelatedFields") ||
            false;
    },
};
</script>
<style>
.v-data-table-header__icon {
    margin-left: -2px;
}
table > thead > tr > th:nth-child(n) > button {
    margin-left: -13px;
    margin-right: -10px;
    width: 10%;
}
.v-text-field__details {
    display: none;
}
</style>
