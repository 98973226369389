<template lang="">
    <div>
        <v-row>
            <v-col cols="12">
                <v-card outlined>
                    <v-card-title
                        style="border-bottom: 1px solid #404040"
                        class="pt-2 pb-2"
                    >
                        <div
                            class="text-left"
                            style="
                                margin-left: 0;
                                width: 50%;
                                font-size: 16px;
                                font-weight: normal;
                            "
                        >
                            SEARCH FILTER
                        </div>
                    </v-card-title>

                    <v-row class="pl-4 pt-2">
                        <v-col cols="12" sm="12" md="4" lg="2" xl="2">
                            <v-autocomplete
                                label="Filter Book"
                                v-model="selectedBook"
                                :items="bookData"
                                item-text="book_name"
                                item-value="book_name"
                                auto-select-first
                                multiple
                                chips
                                deletable-chips
                                small-chips
                                hide-details
                            ></v-autocomplete>
                        </v-col>
                        <v-col cols="12" sm="12" md="4" lg="2" xl="2">
                            <v-datetime-picker
                                label="Start Date"
                                v-model="selectedStartDate"
                                hide-details
                            ></v-datetime-picker>
                        </v-col>
                        <v-col cols="12" sm="12" md="4" lg="2" xl="2">
                            <v-datetime-picker
                                label="End Date"
                                v-model="selectedEndDate"
                                hide-details
                            ></v-datetime-picker>
                        </v-col>
                        <v-col cols="12" sm="12" md="4" lg="2" xl="2">
                            <div
                                class="text-left"
                                style="margin-left: 20px; margin-top: 20px"
                            >
                                <div>
                                    <v-btn
                                        text
                                        icon
                                        color="blue lighten-2"
                                        @click="showMore()"
                                        v-if="!showMoreStatus"
                                    >
                                        More
                                        <v-icon large>
                                            mdi-chevron-down
                                        </v-icon>
                                    </v-btn>
                                    <v-btn
                                        text
                                        icon
                                        color="blue lighten-2"
                                        @click="showMore()"
                                        v-if="showMoreStatus"
                                    >
                                        Fewer
                                        <v-icon large> mdi-chevron-up </v-icon>
                                    </v-btn>
                                </div>
                            </div>
                        </v-col>
                    </v-row>

                    <v-row class="pl-4" v-if="showMoreStatus">
                        <v-col cols="12" sm="12" md="4" lg="2" xl="2">
                            <v-autocomplete
                                label="Order By"
                                v-model="selectedOrderBy"
                                :items="filterOrderBy"
                                auto-select-first
                                chips
                                clearable
                                deletable-chips
                                small-chips
                                hide-details
                            ></v-autocomplete>
                        </v-col>

                        <v-col cols="2">
                            <v-switch
                                style="width: 100%"
                                v-model="isAsc"
                                :label="`Is Ascending Order?`"
                                hide-details
                            ></v-switch>
                        </v-col>
                        <v-col cols="12" sm="12" md="4" lg="2" xl="2">
                            <v-autocomplete
                                label="Show Result"
                                v-model="selectedLimit"
                                :items="filterShowResult"
                                auto-select-first
                                chips
                                clearable
                                deletable-chips
                                small-chips
                                hide-details
                            ></v-autocomplete>
                        </v-col>

                        <v-col cols="12" sm="12" md="12" lg="12" xl="12">
                            <v-text-field
                                label="Login (Seprate by Comma, Eg: 10000000,200000000,300000000)"
                                v-model="selectedLogin"
                                hide-details
                            ></v-text-field>
                        </v-col>
                    </v-row>

                    <v-row class="pa-4 pt-0">
                        <v-col cols="4">
                            <v-btn
                                small
                                v-on:click="fetchTransactionRecord()"
                                :loading="loading"
                                color="primary"
                                >SEARCH</v-btn
                            >
                        </v-col>
                    </v-row>
                </v-card>
            </v-col>
        </v-row>

        <v-row>
            <v-col cols="12">
                <v-card outlined>
                    <v-card-title
                        style="border-bottom: 1px solid #404040"
                        class="pt-2 pb-2"
                    >
                        <div
                            class="text-left"
                            style="
                                margin-left: 0;
                                width: 50%;
                                font-size: 16px;
                                font-weight: normal;
                            "
                        >
                            RESULT
                        </div>
                        <div
                            class="text-right"
                            style="margin-right: 0; width: 50%"
                        >
                            <download-excel
                                :escapeCsv="false"
                                :data="tradesList"
                                name="Profit Loss Search.csv"
                                type="csv"
                            >
                                <v-icon color="green" dark dense>
                                    mdi-microsoft-excel
                                </v-icon>
                            </download-excel>
                        </div>
                    </v-card-title>

                    <v-row>
                        <v-col cols="12">
                            <v-card flat>
                                <v-data-table
                                    :header-props="{ sortIcon: null }"
                                    dense
                                    :search="search"
                                    :headers="headers"
                                    :items="filteredDataSource"
                                    class="elevation-1"
                                    :items-per-page="10"
                                >
                                    <template v-slot:header.login="{ header }">
                                        {{ header.text }}
                                        <v-menu
                                            offset-y
                                            :close-on-content-click="false"
                                            top
                                        >
                                            <template
                                                v-slot:activator="{ on, attrs }"
                                            >
                                                <v-btn
                                                    icon
                                                    v-bind="attrs"
                                                    v-on="on"
                                                    @click="
                                                        setFocus(`loginName`)
                                                    "
                                                >
                                                    <v-icon
                                                        small
                                                        :color="
                                                            loginName
                                                                ? 'primary'
                                                                : ''
                                                        "
                                                    >
                                                        mdi-filter
                                                    </v-icon>
                                                </v-btn>
                                            </template>
                                            <div
                                                style="
                                                    background-color: #1e1e1e;
                                                    width: 280px;
                                                "
                                            >
                                                <v-text-field
                                                    v-model="loginName"
                                                    class="px-4"
                                                    type="text"
                                                    ref="loginName"
                                                    label="Enter the search term"
                                                    style="width: 100%"
                                                ></v-text-field>
                                                <v-btn
                                                    @click="loginName = ''"
                                                    small
                                                    text
                                                    color="primary"
                                                    class="ml-2 mb-2"
                                                >
                                                    Clean
                                                </v-btn>
                                            </div>
                                        </v-menu>
                                    </template>
                                    <template v-slot:header.open="{ header }">
                                        {{ header.text }}
                                        <v-menu
                                            offset-y
                                            :close-on-content-click="false"
                                            top
                                        >
                                            <template
                                                v-slot:activator="{ on, attrs }"
                                            >
                                                <v-btn
                                                    icon
                                                    v-bind="attrs"
                                                    v-on="on"
                                                    @click="
                                                        setFocus(`openName`)
                                                    "
                                                >
                                                    <v-icon
                                                        small
                                                        :color="
                                                            openName
                                                                ? 'primary'
                                                                : ''
                                                        "
                                                    >
                                                        mdi-filter
                                                    </v-icon>
                                                </v-btn>
                                            </template>
                                            <div
                                                style="
                                                    background-color: #1e1e1e;
                                                    width: 280px;
                                                "
                                            >
                                                <v-text-field
                                                    v-model="openName"
                                                    class="px-4"
                                                    type="text"
                                                    ref="openName"
                                                    label="Floating >="
                                                    style="width: 100%"
                                                ></v-text-field>
                                                <v-btn
                                                    @click="openName = ''"
                                                    small
                                                    text
                                                    color="primary"
                                                    class="ml-2 mb-2"
                                                >
                                                    Clean
                                                </v-btn>
                                            </div>
                                        </v-menu>
                                    </template>
                                    <template v-slot:header.closed="{ header }">
                                        {{ header.text }}
                                        <v-menu
                                            offset-y
                                            :close-on-content-click="false"
                                            top
                                        >
                                            <template
                                                v-slot:activator="{ on, attrs }"
                                            >
                                                <v-btn
                                                    icon
                                                    v-bind="attrs"
                                                    v-on="on"
                                                    @click="
                                                        setFocus(`closedName`)
                                                    "
                                                >
                                                    <v-icon
                                                        small
                                                        :color="
                                                            closedName
                                                                ? 'primary'
                                                                : ''
                                                        "
                                                    >
                                                        mdi-filter
                                                    </v-icon>
                                                </v-btn>
                                            </template>
                                            <div
                                                style="
                                                    background-color: #1e1e1e;
                                                    width: 280px;
                                                "
                                            >
                                                <v-text-field
                                                    v-model="closedName"
                                                    class="px-4"
                                                    type="text"
                                                    ref="closedName"
                                                    label="Closed >="
                                                    style="width: 100%"
                                                ></v-text-field>
                                                <v-btn
                                                    @click="closedName = ''"
                                                    small
                                                    text
                                                    color="primary"
                                                    class="ml-2 mb-2"
                                                >
                                                    Clean
                                                </v-btn>
                                            </div>
                                        </v-menu>
                                    </template>
                                    <template v-slot:header.net="{ header }">
                                        {{ header.text }}
                                        <v-menu
                                            offset-y
                                            :close-on-content-click="false"
                                            top
                                        >
                                            <template
                                                v-slot:activator="{ on, attrs }"
                                            >
                                                <v-btn
                                                    icon
                                                    v-bind="attrs"
                                                    v-on="on"
                                                    @click="setFocus(`netName`)"
                                                >
                                                    <v-icon
                                                        small
                                                        :color="
                                                            netName
                                                                ? 'primary'
                                                                : ''
                                                        "
                                                    >
                                                        mdi-filter
                                                    </v-icon>
                                                </v-btn>
                                            </template>
                                            <div
                                                style="
                                                    background-color: #1e1e1e;
                                                    width: 280px;
                                                "
                                            >
                                                <v-text-field
                                                    v-model="netName"
                                                    class="px-4"
                                                    type="text"
                                                    ref="netName"
                                                    label="Net >="
                                                    style="width: 100%"
                                                >
                                                </v-text-field>
                                                <v-btn
                                                    @click="netName = ''"
                                                    small
                                                    text
                                                    color="primary"
                                                    class="ml-2 mb-2"
                                                >
                                                    Clean
                                                </v-btn>
                                            </div>
                                        </v-menu>
                                    </template>
                                    <template
                                        v-slot:header.deposit="{ header }"
                                    >
                                        {{ header.text }}
                                        <v-menu
                                            offset-y
                                            :close-on-content-click="false"
                                            top
                                        >
                                            <template
                                                v-slot:activator="{ on, attrs }"
                                            >
                                                <v-btn
                                                    icon
                                                    v-bind="attrs"
                                                    v-on="on"
                                                    @click="
                                                        setFocus(`depositName`)
                                                    "
                                                >
                                                    <v-icon
                                                        small
                                                        :color="
                                                            depositName
                                                                ? 'primary'
                                                                : ''
                                                        "
                                                    >
                                                        mdi-filter
                                                    </v-icon>
                                                </v-btn>
                                            </template>
                                            <div
                                                style="
                                                    background-color: #1e1e1e;
                                                    width: 280px;
                                                "
                                            >
                                                <v-text-field
                                                    v-model="depositName"
                                                    class="px-4"
                                                    type="text"
                                                    ref="depositName"
                                                    label="Deposit >="
                                                    style="width: 100%"
                                                ></v-text-field>
                                                <v-btn
                                                    @click="depositName = ''"
                                                    small
                                                    text
                                                    color="primary"
                                                    class="ml-2 mb-2"
                                                >
                                                    Clean
                                                </v-btn>
                                            </div>
                                        </v-menu>
                                    </template>
                                    <template v-slot:header.book="{ header }">
                                        {{ header.text }}
                                        <v-menu
                                            offset-y
                                            :close-on-content-click="false"
                                            top
                                        >
                                            <template
                                                v-slot:activator="{ on, attrs }"
                                            >
                                                <v-btn
                                                    icon
                                                    v-bind="attrs"
                                                    v-on="on"
                                                    @click="
                                                        setFocus(`bookName`)
                                                    "
                                                >
                                                    <v-icon
                                                        small
                                                        :color="
                                                            bookName
                                                                ? 'primary'
                                                                : ''
                                                        "
                                                    >
                                                        mdi-filter
                                                    </v-icon>
                                                </v-btn>
                                            </template>
                                            <div
                                                style="
                                                    background-color: #1e1e1e;
                                                    width: 280px;
                                                "
                                            >
                                                <v-text-field
                                                    v-model="bookName"
                                                    class="px-4"
                                                    type="text"
                                                    ref="bookName"
                                                    label="Enter the search term"
                                                    style="width: 100%"
                                                ></v-text-field>
                                                <v-btn
                                                    @click="bookName = ''"
                                                    small
                                                    text
                                                    color="primary"
                                                    class="ml-2 mb-2"
                                                >
                                                    Clean
                                                </v-btn>
                                            </div>
                                        </v-menu>
                                    </template>
                                    <template v-slot:header.group="{ header }">
                                        {{ header.text }}
                                        <v-menu
                                            offset-y
                                            :close-on-content-click="false"
                                            top
                                        >
                                            <template
                                                v-slot:activator="{ on, attrs }"
                                            >
                                                <v-btn
                                                    icon
                                                    v-bind="attrs"
                                                    v-on="on"
                                                    @click="
                                                        setFocus(`groupName`)
                                                    "
                                                >
                                                    <v-icon
                                                        small
                                                        :color="
                                                            groupName
                                                                ? 'primary'
                                                                : ''
                                                        "
                                                    >
                                                        mdi-filter
                                                    </v-icon>
                                                </v-btn>
                                            </template>
                                            <div
                                                style="
                                                    background-color: #1e1e1e;
                                                    width: 280px;
                                                "
                                            >
                                                <v-text-field
                                                    v-model="groupName"
                                                    class="px-4"
                                                    type="text"
                                                    ref="groupName"
                                                    label="Enter the search term"
                                                    style="width: 100%"
                                                ></v-text-field>
                                                <v-btn
                                                    @click="groupName = ''"
                                                    small
                                                    text
                                                    color="primary"
                                                    class="ml-2 mb-2"
                                                >
                                                    Clean
                                                </v-btn>
                                            </div>
                                        </v-menu>
                                    </template>
                                    <template v-slot:header.CCY="{ header }">
                                        {{ header.text }}
                                        <v-menu
                                            offset-y
                                            :close-on-content-click="false"
                                            top
                                        >
                                            <template
                                                v-slot:activator="{ on, attrs }"
                                            >
                                                <v-btn
                                                    icon
                                                    v-bind="attrs"
                                                    v-on="on"
                                                    @click="setFocus(`ccyName`)"
                                                >
                                                    <v-icon
                                                        small
                                                        :color="
                                                            ccyName
                                                                ? 'primary'
                                                                : ''
                                                        "
                                                    >
                                                        mdi-filter
                                                    </v-icon>
                                                </v-btn>
                                            </template>
                                            <div
                                                style="
                                                    background-color: #1e1e1e;
                                                    width: 280px;
                                                "
                                            >
                                                <v-text-field
                                                    v-model="ccyName"
                                                    class="px-4"
                                                    type="text"
                                                    ref="ccyName"
                                                    label="Enter the search term"
                                                    style="width: 100%"
                                                ></v-text-field>
                                                <v-btn
                                                    @click="ccyName = ''"
                                                    small
                                                    text
                                                    color="primary"
                                                    class="ml-2 mb-2"
                                                >
                                                    Clean
                                                </v-btn>
                                            </div>
                                        </v-menu>
                                    </template>
                                    <template v-slot:item.floating="{ item }">
                                        {{
                                            numberWithCommas(
                                                item.floating.toFixed(2)
                                            )
                                        }}
                                    </template>
                                    <template v-slot:item.closed="{ item }">
                                        {{
                                            numberWithCommas(
                                                item.closed.toFixed(2)
                                            )
                                        }}
                                    </template>
                                    <template v-slot:item.net="{ item }">
                                        {{
                                            numberWithCommas(
                                                item.net.toFixed(2)
                                            )
                                        }}
                                    </template>

                                    <template v-slot:item.deposit="{ item }">
                                        {{
                                            numberWithCommas(
                                                item.deposit.toFixed(2)
                                            )
                                        }}
                                    </template>
                                </v-data-table>
                            </v-card>
                        </v-col>
                    </v-row>
                </v-card>
            </v-col>
        </v-row>
    </div>
</template>
<script>
import { mapState, mapActions } from "vuex";
import { helper } from "@components/mixins/helper";
import { permission } from "@components/mixins/permission";
import { snackbar } from "@components/mixins/snackbar";

export default {
    mixins: [permission, snackbar, helper],
    data() {
        return {
            showMoreStatus: false,
            loginName: "",
            openName: "",
            closedName: "",
            netName: "",
            depositName: "",
            bookName: "",
            groupName: "",
            ccyName: "",
            selectedBook: [],
            selectedOffsetStart: 0,
            selectedLimit: 100,
            selectedOrderBy: null,
            selectedStartDate: null,
            selectedEndDate: null,
            selectedLogin: "",
            isAsc: false,
            search: "",
        };
    },
    computed: {
        ...mapState("ProfitLossSearch", [
            "csvHeader",
            "filterOrderBy",
            "headers",
            "filterShowResult",
            "loading",
            "tradesList",
        ]),
        ...mapState("SettingsBooks", ["bookData"]),
        filteredDataSource() {
            const conditions = [];
            if (this.loginName) {
                conditions.push(this.filterLoginName);
            }
            if (this.openName) {
                conditions.push(this.filterOpenName);
            }
            if (this.closedName) {
                conditions.push(this.filterClosedName);
            }
            if (this.netName) {
                conditions.push(this.filterNetName);
            }
            if (this.depositName) {
                conditions.push(this.filterDepositName);
            }
            if (this.bookName) {
                conditions.push(this.filterBookName);
            }
            if (this.groupName) {
                conditions.push(this.filterGroupName);
            }
            if (this.ccyName) {
                conditions.push(this.filterCcyName);
            }
            if (conditions.length > 0) {
                return this.tradesList.filter((item) => {
                    return conditions.every((condition) => {
                        return condition(item);
                    });
                });
            }
            return this.tradesList;
        },
    },
    watch: {
        bookData(nv) {
            if (nv.length !== 0) this.selectedBook = [nv[0].book_name];
        },
    },
    methods: {
        ...mapActions("ProfitLossSearch", ["getProfitLossAction"]),
        fetchTransactionRecord() {
            const params = {
                book: this.selectedBook,
                start_date: this.selectedStartDate,
                end_date: this.selectedEndDate,
                order_by: this.selectedOrderBy,
                is_asc: this.isAsc,
                limit: this.selectedLimit,
                login: this.selectedLogin,
            };
            this.getProfitLossAction(params);
        },
        showMore() {
            this.showMoreStatus = !this.showMoreStatus;
        },
        setFocus(item) {
            setTimeout(() => {
                this.$refs[`${item}`].focus();
            }, 200);
        },
        filterLoginName(item) {
            return item.login.toString().includes(this.loginName);
        },
        filterBookName(item) {
            return item.book
                .toLowerCase()
                .includes(this.bookName.toLowerCase());
        },
        filterGroupName(item) {
            return item.group
                .toLowerCase()
                .includes(this.groupName.toLowerCase());
        },
        filterCcyName(item) {
            return item.CCY.toLowerCase().includes(this.ccyName.toLowerCase());
        },
        filterDepositName(item) {
            return item.deposit >= this.depositName;
        },
        filterOpenName(item) {
            return item.open >= this.openName;
        },
        filterClosedName(item) {
            return item.closed >= this.closedName;
        },
        filterNetName(item) {
            return item.net >= this.netName;
        },
    },
    mounted() {
        const currentDate = new Date();
        const oneDayBefore = 24 * 60 * 60 * 1000 * 1; // 5 days
        currentDate.setTime(currentDate.getTime() - oneDayBefore);
        this.selectedEndDate = currentDate;
        const dateOffset = 24 * 60 * 60 * 1000 * 60; // 5 days
        const startDate = new Date();
        startDate.setTime(startDate.getTime() - dateOffset);
        this.selectedStartDate = startDate;
    },
};
</script>
<style>
.v-text-field__details {
    display: none;
}
</style>
