<template>
    <div>
        <v-row>
            <v-col cols="12">
                <v-card outlined>
                    <v-card-title
                        style="border-bottom: 1px solid #404040"
                        class="pt-2 pb-2"
                    >
                        <div
                            class="text-left"
                            style="
                                margin-left: 0;
                                width: 90%;
                                font-size: 16px;
                                font-weight: normal;
                            "
                        >
                            REQUEST STATUS SUMMARY
                        </div>
                        <div
                            class="text-right"
                            style="
                                margin-left: 0;
                                width: 10%;
                                font-size: 16px;
                                font-weight: normal;
                            "
                        >
                            <v-btn
                                color="primary"
                                icon
                                @click="refresh"
                                :loading="loading"
                                x-small
                                id="searchDownloadRefresh"
                            >
                                <v-icon>mdi-refresh</v-icon>
                            </v-btn>
                        </div>
                    </v-card-title>
                    <v-data-table
                        dense
                        :headers="headers"
                        :loading="loading"
                        :items="tradesList"
                        id="searchDownloadTable"
                        class="elevation-1"
                        :header-props="{ sortIcon: null }"
                        :footer-props="{
                            'items-per-page-options': [15, 30],
                            'show-current-page': true,
                        }"
                    >
                        <template v-slot:item.request_param="{ item }">
                            <span
                                v-html="item.request_param.split('<br/>')[0]"
                            ></span>
                            <span>...</span>
                            <v-tooltip right>
                                <template v-slot:activator="{ on, attrs }">
                                    <v-icon
                                        dark
                                        v-bind="attrs"
                                        v-on="on"
                                        small
                                        class="ml-1"
                                    >
                                        mdi-eye
                                    </v-icon>
                                </template>
                                <div v-html="item.request_param"></div>
                            </v-tooltip>
                        </template>
                        <template v-slot:item.download_status="{ item }">
                            <div v-if="item.download_status === 'Pending'">
                                {{ item.download_status }}
                                <v-icon class="ml-1" small>
                                    mdi-clock-outline
                                </v-icon>
                            </div>
                            <div
                                v-else-if="item.download_status === 'Executing'"
                            >
                                {{ item.download_status }}
                                <v-icon class="ml-1" small>
                                    mdi-cog-clockwise
                                </v-icon>
                            </div>
                            <div v-else>
                                <a @click="downloadFile(item)">Download</a>
                            </div>
                        </template>
                    </v-data-table>
                </v-card>
            </v-col>
        </v-row>
    </div>
</template>
<script>
import { mapState, mapActions } from "vuex";
import { snackbar } from "@components/mixins/snackbar";
import { permission } from "@components/mixins/permission";
import { helper } from "@components/mixins/helper";

export default {
    mixins: [snackbar, permission, helper],
    data() {
        return {};
    },
    computed: {
        ...mapState("DownloadStatus", ["headers", "loading", "tradesList"]),
    },
    watch: {},
    methods: {
        ...mapActions("DownloadStatus", [
            "getDownloadStatusAction",
            "downloadFileAction",
        ]),
        refresh() {
            this.getDownloadStatusAction();
        },
        downloadFile(item) {
            this.downloadFileAction(item);
        },
    },
    mounted() {
        this.refresh();
    },
};
</script>
<style>
.v-text-field__details {
    display: none;
}
</style>
