var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[_c('v-row',[_c('v-col',{attrs:{"cols":"12"}},[_c('v-card',{attrs:{"outlined":""}},[_c('v-card-title',{staticClass:"pt-2 pb-2",staticStyle:{"border-bottom":"1px solid #404040"}},[_c('div',{staticClass:"text-left",staticStyle:{"margin-left":"0","width":"90%","font-size":"16px","font-weight":"normal"}},[_vm._v(" REQUEST STATUS SUMMARY ")]),_c('div',{staticClass:"text-right",staticStyle:{"margin-left":"0","width":"10%","font-size":"16px","font-weight":"normal"}},[_c('v-btn',{attrs:{"color":"primary","icon":"","loading":_vm.loading,"x-small":"","id":"searchDownloadRefresh"},on:{"click":_vm.refresh}},[_c('v-icon',[_vm._v("mdi-refresh")])],1)],1)]),_c('v-data-table',{staticClass:"elevation-1",attrs:{"dense":"","headers":_vm.headers,"loading":_vm.loading,"items":_vm.tradesList,"id":"searchDownloadTable","header-props":{ sortIcon: null },"footer-props":{
                        'items-per-page-options': [15, 30],
                        'show-current-page': true,
                    }},scopedSlots:_vm._u([{key:"item.request_param",fn:function(ref){
                    var item = ref.item;
return [_c('span',{domProps:{"innerHTML":_vm._s(item.request_param.split('<br/>')[0])}}),_c('span',[_vm._v("...")]),_c('v-tooltip',{attrs:{"right":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
                    var on = ref.on;
                    var attrs = ref.attrs;
return [_c('v-icon',_vm._g(_vm._b({staticClass:"ml-1",attrs:{"dark":"","small":""}},'v-icon',attrs,false),on),[_vm._v(" mdi-eye ")])]}}],null,true)},[_c('div',{domProps:{"innerHTML":_vm._s(item.request_param)}})])]}},{key:"item.download_status",fn:function(ref){
                    var item = ref.item;
return [(item.download_status === 'Pending')?_c('div',[_vm._v(" "+_vm._s(item.download_status)+" "),_c('v-icon',{staticClass:"ml-1",attrs:{"small":""}},[_vm._v(" mdi-clock-outline ")])],1):(item.download_status === 'Executing')?_c('div',[_vm._v(" "+_vm._s(item.download_status)+" "),_c('v-icon',{staticClass:"ml-1",attrs:{"small":""}},[_vm._v(" mdi-cog-clockwise ")])],1):_c('div',[_c('a',{on:{"click":function($event){return _vm.downloadFile(item)}}},[_vm._v("Download")])])]}}])})],1)],1)],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }